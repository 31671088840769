$container-max-widths: (sm: 540px,
md: 720px,
lg: 960px,
xl: 1100px) !default;

$grid-breakpoints: (xs: 0,
sm: 576px,
md: 768px,
lg: 992px,
xl: 1200px) !default;


$theme-colors: (
    "primary":#abb97e,
    "secondary": #898989,
    "light": #fafafa,
    "dark": #969188,
    "grey": #374252,
    "alt-tan": #f1e9dd,
    ) !default;
    
$very-light: #969ca6;
$body-color: #969188;
$body-background: url('/PrositesStyles/StyleLibrary/6231/images/background.jpg') top center no-repeat #f3efe6;
$spacer: 1rem;
$enable-rounded: true;

$border-width: 1px !default;
$border-color: rgba(map-get($theme-colors, dark), 0.1) !default;

$border-radius: 0 !default;
$border-radius-lg: 0 !default;
$border-radius-sm: 0 !default;

.list-group-item {
  background-color: map-get($theme-colors, alt-tan) !important;
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');

$font-family-sans-serif: 'Open Sans', sans-serif;
$font-family-serif: 'Californian FB', serif;

$font-size-base: 1rem !default; 
$font-size-lg: ($font-size-base * 1.25) !default;
$font-size-sm: ($font-size-base * 0.875) !default;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

$h1-font-size: $font-size-base * 1.75 !default;
$h2-font-size: $font-size-base * 1.3 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.625 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base * 1 !default;

$headings-margin-bottom: 1rem !default;
$headings-font-family: 'Optima LT Std';
$headings-font-weight: 300 !default;
$headings-line-height: 1.2 !default;
$headings-color: inherit !default;

$nav-font-family: 'Optima LT Std';

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.75rem !default;

$display1-weight: 300 !default;
$display2-weight: 300 !default;
$display3-weight: 300 !default;
$display4-weight: 300 !default;
$display-font-family: $font-family-serif !default;
$display-line-height: 1.1 !default;

$lead-font-size: ($font-size-base * 1.5) !default;
$lead-font-weight: 300 !default;
$lead-line-height: 1.5 !default;

$small-font-size: 0.75rem !default;
$small-2-font-size: 0.9rem !default;
$small-line-height: 1.4 !default;

$blockquote-font-size: ($font-size-base * 1.25) !default;

$hr-border-color: rgba(map-get($theme-colors, dark), 0.1) !default;
$hr-border-width: 1px !default;

$input-placeholder-color: #b7bbc0;
$input-border-color: $border-color;
$input-padding-y: 0.5rem;
$input-padding-x: 0.75rem;
$input-box-shadow: none;

$card-spacer-x: 1.875rem;
$card-bg: #f1e9dd;


$shadow-0: none;
$shadow-1: 0px 5px 25px 0px rgba(0, 0, 0, 0.1);
$shadow-2: 0px 13px 40px rgba(0, 0, 0, 0.2);

$drop-down-color: map-get($theme-colors, dark);
$drop-down-background: #fff;
$drop-down-hover-color: map-get($theme-colors, dark);
$drop-down-hover-background: rgba(map-get($theme-colors, dark), 0.1);
$drop-down-info-font-weight: 600;


@import "bootstrap/bootstrap.scss";
@import "bootstrap/bootstrap-grid.scss";
@import "bootstrap/bootstrap-reboot.scss";
@import "bootstrap/style-specific.scss";


html {
  body {
    @media screen and (min-width: 1600px) {
      section.section-testimonials,
      section.section-contact-form {
          background-size: 100% auto;
      }
    }
    nav.navbar {
      @media screen and (min-width: map-get($grid-breakpoints, lg )) {
        ul.nav.navbar-nav {
          > li {
            > a {
              padding-left: 0 !important;
              padding-right: 0 !important;
              margin: 0.25rem 0.6rem !important;
            }
            &.active {
              > a {
                box-shadow: inset 0 -3px 0 0 map-get($theme-colors, primary);
              }
            }
          }
        }
      }
    }

    .address-bar {
      transform: translate3d(0, -100%, 0);
      background: -moz-linear-gradient(left, rgba(241,234,222,0) 0%, rgba(241,234,222,0.9) 33%, rgba(241,234,222,0.9) 50%, rgba(241,234,222,0.9) 67%, rgba(241,234,222,0) 100%); 
      background: -webkit-linear-gradient(left, rgba(241,234,222,0) 0%,rgba(241,234,222,0.9) 33%,rgba(241,234,222,0.9) 50%,rgba(241,234,222,0.9) 67%,rgba(241,234,222,0) 100%); 
      background: linear-gradient(to right, rgba(241,234,222,0) 0%,rgba(241,234,222,0.9) 33%,rgba(241,234,222,0.9) 50%,rgba(241,234,222,0.9) 67%,rgba(241,234,222,0) 100%); 
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f1eade', endColorstr='#00f1eade',GradientType=1 );
    }

    footer {
      background-color: #f2eee5;

      #main-menu {
        list-style-type: none;
        margin: 0;
        padding:0;
        > li {
          display: inline-block;
          margin-bottom: 10px;
          box-shadow: 1px 0 0 0 #bbb5ac;
          &:last-child {
            box-shadow: none;
          }
          a {
            color: #bbb5ac;
            padding-bottom: 1px;
            padding-top: 0;
            &:hover {
              color: map-get($theme-colors, primary);
            }
          }
        }
        ul {
          display: none !important;
        }
      }
      
    }

    section[id*="interiorBanner"] {
        background-color: #f2eee5 !important;
    }
    section[id*="interiorBanner"] a,
    section[id*="interiorBanner"] a img {
        margin: 0 auto;
        display: block;
    }

    #fullPageWrapper {
      background-color: #f2eee5;
    }

    &.interior #fullPageWrapper {
      background-image: none;
    }

    .custom-control-label:before {
      background-color: $white;
    }

    &[data-page-type="contact"] {
      .form-group {
        input, textarea {
          border: 1px solid rgba(map-get($theme-colors, dark), 0.25) !important;
        }
      }
    }

    .section-testimonials {
      .panel-image {
        background-color: #f2eee5 !important;
        > div {
          transition: opacity 1s ease;
          opacity: 0;
          &.active {
            opacity: 1;
            @include media-breakpoint-down(sm) {
              opacity: 0.3;
            }
          }
        }
      }
    }

    header {
      position: relative;
      &:before {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: -30px;
          background: rgb(255,250,249);
          background: -moz-linear-gradient(top, rgba(255,250,249,1) 0%, rgba(243,239,230,1) 100%); 
          background: -webkit-linear-gradient(top, rgba(255,250,249,1) 0%,rgba(243,239,230,1) 100%);
          background: linear-gradient(to bottom, rgba(255,250,249,1) 0%,rgba(243,239,230,1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fffaf9', endColorstr='#f3efe6',GradientType=0 );
      
      }
    }
    [id*="interiorBanner"],
    .home-banner {
      z-index: 10;
      position: relative;
    }
    .section-call-to-actions a,
    .wysiwyg + div a {
        transition: 0.25s ease;
        display: block;
    }
    .section-call-to-actions a:hover,
    .wysiwyg + div a:hover {
        opacity: 0.85;
    }
    .section-call-to-actions a:hover {
        transform: translate3d(0,-2px,0);
    }

    .address-bar .container-inner ul {
        list-style: none;
        margin: 0;
        padding: 10px 0;
    }
    .address-bar .container-inner li,
    .address-bar .container-inner span {
        display: inline-block;
    }
    .address-bar .container-inner h2,
    .address-bar .container-inner .adcityname {
        display: none;
    }
    .address-bar .col-md-3 {
        display: block !important;
        max-width: 100% !important;
    }
    .address-bar .adgrp1:before {
        content: "|";
        margin: 0 10px 0 5px;
    }
    .address-bar .adgrp1:after {
        content: "|";
        margin: 0 10px 0 5px;
    }
    .address-bar .adgrp2 >li:first-child:after {
        content: "|";
        margin: 0 5px 0 14px;
    }

    [class*="btn"]:hover {
      color: #fff !important;
    }
    [class*="btn"] {
      &, &.disabled {
        color: #fff !important;
      }
    }
    [class*="btn"]:hover {
      opacity: 0.85;
    }

  }
}


html body section[id*="interiorBanner"]  {
  max-width: 100%;
  margin: 0 auto;
  height: 260px;
  background-color: transparent;
}

html body section[id*="interiorBanner"] .panel-image {
  background-size: contain;
  }

  
@media screen and (max-width: 1250px) {
  html body section[id*="interiorBanner"]  .panel-image  {
    background-size: 140%;
    }
}

@media screen and (max-width: 992px) {
  html body section[id*="interiorBanner"]  .panel-image  {
    background-size: contain;
    }
}
@media screen and (max-width: 800px) and (orientation: portrait) {
  html body section[id*="interiorBanner"] {
    
  }
}

@media screen and (max-width: 600px) {
  html body section[id*="interiorBanner"] {
    height: 180px;
  }
}

@media screen and (max-width: 480px) {
  html body section[id*="interiorBanner"] {
    height: 180px;
  }
  html body section[id*="interiorBanner"] .panel-image {
  background-size: 130%;
  }
}

@media screen and (min-width: 992px) {
ul.nav > li > a .sub-arrow {
  display: none!important;
}
}

ul.dropdown-menu > li > a .sub-arrow {
  top: 62%;
  @include media-breakpoint-up(lg) {
    transform: rotate(-90deg);
  }
}

.nav-link {
  padding: .5rem .5rem;
}

#footer p {
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
  font-size: 15px;
}
  
#footer a {
    font-weight: bold;
    color: #bbb5ac;
}

.form .card {
  border: 0px;
}

html body.interior #fullPageWrapper {
  background: url('/PrositesStyles/StyleLibrary/6231/images/interior-bg.jpg') top center no-repeat #f3efe6!important;
}